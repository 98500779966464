import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const StudentRegistrationForm = () => {
    //Get Items From Local Storage
    const course = JSON.parse(localStorage.getItem('selectedCourse'));
    const attendees = Number(localStorage.getItem('attendees'));

    const firstNameRefs = useRef([]);
    //State items for Form
    const [currentAttendeeIndex, setCurrentAttendeeIndex] = useState(0);
    const [timeoutId, setTimeoutId] = useState(null);
    const [checkedAttendees, setCheckedAttendees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('');

    const handleEmailChange = debounce((event, index) => {
        // cancel any current timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (checkedAttendees.includes(index)) {
            return;
        }

        // Start new timeout
        const newTimeoutId = setTimeout(() => {
            setIsLoading(true);
            axios
                .get('api/check-student', {
                    params: {
                        email: currentEmail,
                    },
                })
                .then((response) => {
                    toastr.success('Student exists');
                    //updat the form Fieldswith the returned data
                    formik.setFieldValue(`students[${index}]`, response.data);
                    // Mark Attendee as Checked
                    setCheckedAttendees([...checkedAttendees, index]);
                })
                .catch((error) => {
                    toastr.error('No Student found');

                    // Leave the fields empty
                    formik.setFieldValue(`students[${index}]`, {
                        email: current.email,
                        firstName: '',
                        lastName: '',
                        lastName: '',
                        dateOfBirth: '',
                        gender: '',
                        ethnicity: '',
                    });
                    // Focus on the First Name input field
                    if (
                        firstNameRefs.current[index] &&
                        document.activeElement !== firstNameRefs.current[index]
                    ) {
                        setTimeout(
                            () => firstNameRefs.current[index].focus(),
                            0,
                        );
                    }

                    setCheckedAttendees([...checkedAttendees, index]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
        setTimeoutId(newTimeoutId);
    }, 300);

    const handleNextClick = () => {
        setCurrentAttendeeIndex(currentAttendeeIndex + 1);
    };

    const formik = useFormik({
        initialValues: {
            // Initialize the form fields here
            students: Array.from({ length: attendees }, () => ({
                email: '',
                firstName: '',
                lastName: '',
                lastName: '',
                dateOfBirth: '',
                gender: '',
                ethnicity: '',
            })),
        },
        onSubmit: (values) => {
            // Handle form submission here
            // You can use the `values` object to access the form data
            axios
                .post('api/register-students', {
                    course,
                    students: values.students,
                    url: 'student-registration',
                })
                .then((response) => {
                    // Display Messgae - Import react toastify
                    return axios.post('/add-to-cart', {
                        course,
                        students: values.students,
                        attendees,
                    });
                })
                .then((response) => {
                    // Redirect User To Ccheckout
                    window.location.href = '/cart';
                })
                .catch((error) => {
                    console.error('Something Went Wrong : ', error);
                });
        },
    });

    return (
        <Tabs
            selectedIndex={currentAttendeeIndex}
            onSelect={setCurrentAttendeeIndex}
        >
            <TabList className="form-max-width mt-3">
                {formik.values.students.map((_, index) => (
                    <Tab key={index}>Attendee {index + 1}</Tab>
                ))}
            </TabList>
            {formik.values.students.map((student, index) => (
                <TabPanel key={index}>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form-max-width"
                        disabled={isLoading}
                    >
                        <div>
                            Student Email:
                            <div className="flex justify-between">
                                <input
                                    name={`students[${index}].email`}
                                    value={student.email}
                                    className="form-control mt-3"
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                        setCurrentEmail(event.target.value);
                                    }}
                                    onBlur={(event) =>
                                        handleEmailChange(
                                            event,
                                            currentAttendeeIndex,
                                        )
                                    }
                                    disabled={isLoading}
                                />
                                <button
                                    className="btn btn-secondary btn-md mt-3 mb-3"
                                    type="button"
                                    onClick={(event) =>
                                        handleEmailChange(
                                            event,
                                            currentAttendeeIndex,
                                        )
                                    }
                                    disabled={isLoading}
                                >
                                    Search
                                </button>
                            </div>
                            First Name:
                            <input
                                ref={(el) =>
                                    (firstNameRefs.current[index] = el)
                                }
                                name={`students[${index}].firstName`}
                                value={student.firstName}
                                className="form-control mt-3"
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            Last Name:
                            <input
                                name={`students[${index}].lastName`}
                                value={student.lastName}
                                className="form-control mt-3"
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            Date of Birth:
                            <input
                                name={`students[${index}].dateOfBirth`}
                                value={student.dateOfBirth}
                                className="form-control mt-3"
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            Gender:
                            <input
                                name={`students[${index}].gender`}
                                value={student.gender}
                                className="form-control mt-3"
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            Ethnicity:
                            <input
                                name={`students[${index}].ethnicity`}
                                value={student.ethnicity}
                                className="form-control mt-3"
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            {/* Add more fields here */}
                        </div>
                        {index < formik.values.students.length - 1 ? (
                            <button
                                type="button"
                                className="btn btn-secondary btn-md mt-3 mb-3"
                                onClick={handleNextClick}
                                disabled={isLoading}
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn add-new-item btn-full-width mt-3 mb-6"
                            >
                                Submit
                            </button>
                        )}

                        {isLoading && <div class="spinner"></div>}
                    </form>
                </TabPanel>
            ))}
        </Tabs>
    );
};

export default StudentRegistrationForm;
