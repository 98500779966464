import Swal from 'sweetalert2';
import axios from 'axios';

function deleteConfirmation(url) {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    let token = document.head.querySelector('meta[name="csrf-token"]');
    if (token) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    } else {
        console.error(
            'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token',
        );
    }

    Swal.fire({
        title: 'Just Checking...Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
        if (result.isConfirmed) {
            // Perform the deletion operation here
            axios
                .delete(url)
                .then(function (response) {
                    // handle success
                    Swal.fire(
                        'Deleted!',
                        'Your page has been deleted.',
                        'success',
                    ).then(() => {
                        window.location.href = '/page/courses'; // replace with the correct URL
                    });
                    // redirect or update the UI here
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }
    });
}

// Export the function so it can be used in other files
export default deleteConfirmation;
