import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const CourseFilter = ({ course }) => {
    console.log('Rendering the Component');
    const [deliveryType, setDeliveryType] = useState(null);
    const [bookingType, setBookingType] = useState(null);
    const [deliveryTypes, setDeliveryTypes] = useState(null);
    const [attendees, setAttendees] = useState(1);
    const [schedules, setSchedules] = useState([]);
    const [refreshCourses, setRefreshCourses] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isOpen, setIsOpen] = useState({
        deliveryType: false,
        bookingType: false,
        attendees: false,
        courses: false,
        register: false,
    });
    const [areCoursesAvailable, setAreCoursesAvailable] = useState(true);

    const handleDeliveryTypeChange = (event) => {
        setDeliveryType(event.target.value);
    };

    // console.log(deliveryType);

    const handleBookingTypeChange = (event) => {
        setBookingType(event.target.value);
    };

    const handleSelectedCourse = (course) => {
        setSelectedCourse(course);
    };
    // console.log(selectedCourse);
    const handleStudentRegistration = (event) => {
        event.preventDefault();
        localStorage.setItem('selectedCourse', JSON.stringify(selectedCourse));
        localStorage.setItem('attendees', attendees);
        window.location.href = '/'; // Temporarily change to a different URL
        setTimeout(() => {
            window.location.href = '/student-registration'; // Change back to the intended URL
        }, 0);
    };

    useEffect(() => {
        axios
            .get('/api/get-delivery-types?course=' + course.id)
            .then((response) => {
                setDeliveryTypes(response.data);
                setDeliveryType(response.data[0].id);
            })
            .catch((error) => {
                toast.error('Sorry - Something has gone Wrong!', error);
            });
    }, [course]);

    useEffect(() => {
        console.log('refresh Courses: ' + refreshCourses);
        if (refreshCourses) {
            axios
                .get(
                    '/api/get-course-schedules?course=' +
                        course.id +
                        '&attendees=' +
                        attendees,
                )
                .then((response) => {
                    if (response.data.error) {
                        console.error(response.data.error);
                        setAreCoursesAvailable(false);
                    } else {
                        setSchedules(response.data);
                        setAreCoursesAvailable(response.data.length > 0);
                    }
                })
                .catch((error) => {
                    toast.error('Sorry - Something has gone Wrong!', error);
                    console.error('There was an error during fetch', error);
                });
        }
    }, [course, refreshCourses]);

    const handleCourseRequest = () => {
        toast.success('enquire about this course');
    };

    const clearFilters = () => {
        setDeliveryType(null);
        setBookingType(null);
        setAttendees(1);
        setRefreshCourses(false);
        setSelectedCourse(null);
        setIsOpen({ deliveryType: true });
        // Add any other state resets here as needed
    };

    return (
        <div>
            <div className="accordion w-full max-w-md">
                <div
                    className="accordion-item"
                    style={{ marginBottom: '20px' }}
                >
                    <button
                        className="btn btn--accordion"
                        onClick={() =>
                            setIsOpen((prev) => ({
                                ...prev,
                                deliveryType: !prev.deliveryType,
                            }))
                        }
                    >
                        Choose Delivery...
                    </button>
                    {isOpen.deliveryType && (
                        <div className="accordion-content">
                            <select
                                className="form-control"
                                value={deliveryType}
                                onChange={handleDeliveryTypeChange}
                                style={{ width: '100%' }}
                            >
                                {deliveryTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                        {type.type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                {deliveryType === '3' && (
                    <div
                        className="accordion-item"
                        style={{ marginBottom: '20px' }}
                    >
                        <button
                            className="btn btn--accordion"
                            onClick={() =>
                                setIsOpen((prev) => ({
                                    ...prev,
                                    bookingType: !prev.bookingType,
                                }))
                            }
                        >
                            Choose Booking Type...
                        </button>
                        {isOpen.bookingType && (
                            <div className="accordion-content">
                                <select
                                    className="form-control"
                                    value={bookingType}
                                    onChange={handleBookingTypeChange}
                                    style={{ width: '100%' }}
                                >
                                    <option value="1">Scheduled</option>
                                    <option value="2">Group</option>
                                </select>
                            </div>
                        )}
                    </div>
                )}
                <div
                    className="accordion-item"
                    style={{ marginBottom: '20px' }}
                >
                    <button
                        className="btn btn--accordion"
                        onClick={() =>
                            setIsOpen((prev) => ({
                                ...prev,
                                attendees: !prev.attendees,
                            }))
                        }
                    >
                        Number of Attendees
                    </button>
                    {isOpen.attendees && (
                        <div className="accordion-content">
                            <input
                                className="form-control"
                                type="number"
                                min="1"
                                value={attendees}
                                onChange={(event) =>
                                    setAttendees(event.target.value)
                                }
                                style={{ width: '100%' }}
                            />
                            <br />
                            <button
                                className="btn btn--accordion btn-primary btn-full-width mt-6"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent the default link behavior
                                    setRefreshCourses(true); // Change the state to trigger the useEffect
                                    setIsOpen((prev) => ({
                                        ...prev,
                                        courses: true,
                                    })); // Assuming 'courses' is the key for the next accordion item
                                }}
                            >
                                Get Available Courses
                            </button>
                        </div>
                    )}
                </div>

                {refreshCourses && (
                    <div
                        className="accordion-item"
                        style={{ marginBottom: '20px' }}
                    >
                        <div
                            className="accordion-content"
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            {schedules.length > 0 ? (
                                <h5>Next Course Are...</h5>
                            ) : null}
                            <select
                                className="form-control"
                                value={selectedCourse?.id}
                                onChange={(event) => {
                                    console.log(
                                        'Selected course ID:',
                                        event.target.value,
                                    );
                                    const selectedCourse = schedules.find(
                                        (course) =>
                                            course.id ===
                                            Number(event.target.value),
                                    );
                                    console.log(
                                        'Selected course:',
                                        selectedCourse,
                                    );
                                    handleSelectedCourse(selectedCourse);
                                }}
                                style={{ width: '100%' }}
                            >
                                <option className="form-control" value="">
                                    Please Choose
                                </option>
                                {schedules.map((course) => (
                                    <option
                                        className="form-control"
                                        key={course.id}
                                        value={course.id}
                                    >
                                        {new Date(course.start_date).getDate() +
                                            '-' +
                                            (new Date(
                                                course.start_date,
                                            ).getMonth() +
                                                1) +
                                            '-' +
                                            new Date(
                                                course.start_date,
                                            ).getFullYear()}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {selectedCourse && (
                    <div
                        className="accordion-item"
                        style={{ marginBottom: '20px' }}
                    >
                        <h5> Who's Attending?</h5>
                        <div className="row">
                            {selectedCourse && (
                                <button
                                    type="button"
                                    className="btn btn-safety btn-full-width"
                                    onClick={(event) =>
                                        handleStudentRegistration(event)
                                    }
                                >
                                    Register Students
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {!areCoursesAvailable && (
                    <div className="accordion-item">
                        <div
                            className="accordion-content"
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            <h5>Sorry - We have No Courses Upcoming...</h5>
                            <button
                                className="btn btn--safety"
                                onClick={handleCourseRequest}
                            >
                                Request Course
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <button className="btn btn-xs btn-secondary" onClick={clearFilters}>
                Clear Filters
            </button>
        </div>
    );
};

export default CourseFilter;
