import React, { Fragment, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import CourseFilter from './Components/CourseFilter';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import StudentRegistrationForm from './Students/StudentRegistrationForm';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import deleteConfirmation from './SweetAlerts/DeleteConfirmation';

document.addEventListener('DOMContentLoaded', (event) => {
    let courseFilterElement = document.getElementById('courseFilter');

    if (courseFilterElement) {
        let course = JSON.parse(
            courseFilterElement.getAttribute('data-course'),
        );
        createRoot(courseFilterElement).render(
            <Fragment>
                <CourseFilter course={course} />
                <ToastContainer />
            </Fragment>,
        );
    }

    let calendarElement = document.getElementById('calendar');

    if (calendarElement) {
        const [events, setEvents] = useState([]);

        useEffect(() => {
            fetch('/api/get-events') // replace with your API endpoint
                .then((response) => response.json())
                .then((data) => setEvents(data));
        }, []);

        createRoot(calendarElement).render(
            <Fragment>
                <FullCalendar plugins={[dayGridPlugin]} events={events} />
                <ToastContainer />
            </Fragment>,
        );
    }
});

document.addEventListener('DOMContentLoaded', (event) => {
    let studentRegistrationFormElement = document.getElementById(
        'studentRegistrationForm',
    );

    if (studentRegistrationFormElement) {
        createRoot(studentRegistrationFormElement).render(
            <Fragment>
                <StudentRegistrationForm />
                <ToastContainer />
            </Fragment>,
        );
    }
});

// Attach the Sweet alert function to the window object so it can be accessed globally
window.deleteConfirmation = deleteConfirmation;
